import { cn } from '../utils';

export type SpinnerProps = React.ComponentProps<'svg'>;

export function Spinner(props: SpinnerProps) {
  const { className, ...rest } = props;
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" className={cn('h-[0.8em] animate-spin', className)} {...rest}>
      <path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z" />
    </svg>
  );
}
