export const getWebsiteOrigin = (origin?: string | null) => _replaceSubdomain(origin, 'dragon');
export const getAppOrigin = (origin?: string | null) => _replaceSubdomain(origin, 'app');
export const getBffProxyOrigin = (origin?: string | null) => _replaceSubdomain(origin, 'bff-proxy');

const _replaceSubdomain = (origin?: string | null, toSubdomain?: string) => {
  if (origin) return replaceSubdomain(origin, toSubdomain);
  if (typeof window === 'undefined' || typeof window.location === 'undefined') return null;
  return replaceSubdomain(window.location.origin, toSubdomain);
};

// Inspired by https://stackoverflow.com/a/54722613/3712591
function replaceSubdomain(uri: string, toSubdomain?: string) {
  const hasSubdomains = Number(uri.match(/\.\w*\b/g)?.length) > 1;
  // Check if we got a subdomain to replace with
  if (!toSubdomain && hasSubdomains) return uri.replace(/:\/\/(\w*\.)/, '://');
  else if (!toSubdomain) return uri;

  const replace = '://' + toSubdomain + '.';
  // Check if we got a subdomain in url
  if (hasSubdomains) return uri.replace(/(:\/\/\w+\.)/, replace);
  return uri.replace(/:\/\/(\w*\.)/, `${replace}$1`);
}
