import * as React from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { Icon } from '../Icon';
import { Spinner } from '../Spinner';
import { cn, useForwardedRef } from '../utils';

export type ButtonProps = Omit<React.ComponentProps<'button'>, 'ref'> &
  VariantProps<typeof buttonClasses> & {
    asChild?: boolean;
    icon?: string;
    iconBefore?: string;
    iconAfter?: string;
    loading?: boolean;
    loadingText?: string;
    className?: string;
  };

// TODO: rename to Button and replace existing buttons with this on in the app
export const ButtonNew = React.forwardRef<HTMLButtonElement, ButtonProps>((props, forwardedRef) => {
  const {
    asChild,
    icon,
    iconAfter,
    iconBefore,
    children,
    variant,
    size,
    className,
    disabled,
    loading,
    loadingText,
    active,
    ...rest
  } = props;
  const Comp = (asChild ? Slot : 'button') as 'button';
  const ref = useForwardedRef(forwardedRef);
  const isIcon = Boolean(icon);
  return (
    <Comp
      ref={ref}
      className={cn('ac-btn', buttonClasses({ variant, size, isIcon, active }), className)}
      disabled={Boolean(disabled || loading)}
      {...rest}
    >
      {!loading && !isIcon && iconBefore && <Icon icon={iconBefore} className={iconClasses()} />}
      {loading && <Spinner className={iconClasses({ isIcon })} />}
      {!loading && icon && <Icon icon={icon} className={iconClasses({ isIcon })} />}
      {!icon && <Slottable>{loading && loadingText ? loadingText : children}</Slottable>}
      {!loading && !isIcon && iconAfter && <Icon icon={iconAfter} className={iconClasses()} />}
    </Comp>
  );
});

const buttonClasses = cva(
  [
    'inline-flex',
    'rounded-l rounded-r',
    'items-center',
    'justify-center',
    'whitespace-nowrap',
    'text-white',
    'gap-2',
    'w-min',
    'focus:enabled:shadow-[0_0_4px_0]',
  ],
  {
    variants: {
      variant: {
        primary:
          'bg-portfolio-500 hover:enabled:bg-portfolio-400 focus:enabled:shadow-portfolio-600 active:enabled:bg-portfolio-600 disabled:bg-portfolio-200',
        secondary:
          'border border-portfolio-500 text-portfolio-500 hover:enabled:bg-portfolio-50 focus:enabled:shadow-portfolio-500 active:enabled:bg-portfolio-100 disabled:border-portfolio-200 disabled:text-portfolio-200',
        ghost:
          'border border-transparent text-portfolio-500 hover:enabled:bg-portfolio-50 focus:enabled:border-portfolio-100 focus:enabled:shadow-none active:enabled:bg-portfolio-100 disabled:text-portfolio-200',
        danger:
          'bg-error-500 hover:enabled:bg-error-400 focus:enabled:shadow-portfolio-600 active:enabled:bg-error-600 disabled:bg-error-200',
        success:
          'bg-success-500 hover:enabled:bg-success-400 focus:enabled:shadow-portfolio-600 active:enabled:bg-success-600 disabled:bg-success-200',
        function:
          'border border-border-medium text-text-secondary hover:enabled:bg-grey-ds-50 focus:enabled:border-portfolio-500 focus:enabled:shadow-none active:enabled:border-border-dark active:enabled:bg-grey-ds-100 active:enabled:text-text-primary disabled:border-border-light disabled:bg-grey-ds-50 disabled:text-text-disabled',
      },
      size: {
        default: 'h-10 px-4 py-2 text-body/5',
        small: 'h-8 px-4 py-2 text-body/5',
        dense: 'h-6 p-2 text-body-small/4',
      },
      isIcon: {
        true: 'p-0',
        false: '',
      },
      active: {
        true: 'z-0',
      },
    },
    compoundVariants: [
      {
        isIcon: true,
        size: 'default',
        className: 'w-10',
      },
      {
        isIcon: true,
        size: 'small',
        className: 'w-8',
      },
      {
        isIcon: true,
        size: 'dense',
        className: 'w-6',
      },
      {
        active: true,
        variant: 'primary',
        className: 'enabled:bg-portfolio-600',
      },
      {
        active: true,
        variant: 'secondary',
        className: 'enabled:bg-portfolio-100',
      },
      {
        active: true,
        variant: 'ghost',
        className: 'enabled:bg-portfolio-100',
      },
      {
        active: true,
        variant: 'danger',
        className: 'enabled:bg-error-600',
      },
      {
        active: true,
        variant: 'success',
        className: 'enabled:bg-success-600',
      },
      {
        active: true,
        variant: 'function',
        className: 'enabled:border-border-dark enabled:bg-grey-ds-100 enabled:text-text-primary',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'default',
      isIcon: false,
      active: false,
    },
  }
);

const iconClasses = cva([], {
  variants: {
    isIcon: {
      true: 'text-heading-5/6',
      false: 'text-body-large/4',
    },
  },
  defaultVariants: {
    isIcon: false,
  },
});
